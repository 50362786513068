import { Flex } from "@chakra-ui/layout";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { TusUpload } from "@intentsify/tus/dist/react";
import { SerializableTalDomainValidationResults } from "@intentsify/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiFetchService, apiService } from "api";
import { StatusModal, Upload } from "components";
import { useConfirm } from "components/ConfirmDialog/useConfirm";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { getUniqueFileName, getUploadStatus } from "./DomainsUploadTus.utils";
import { ValidationResultsModal } from "./ValidationResultsModal";

export function DomainsUploadTus(props: {
  tusUpload: TusUpload;
  uploadedFileNames?: string[];
  onFileUpload: (
    tempFileName: string,
    fileName: string,
    validationResults: SerializableTalDomainValidationResults
  ) => void;
}) {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const toast = useToast();

  const files = props.tusUpload.useFiles();
  const uploadFile = props.tusUpload.useUploadFile();
  const progress = props.tusUpload.useProgress();
  const resumeUploads = props.tusUpload.useResumeUploads();
  const removeFile = props.tusUpload.useRemoveFile();
  const removeAllFiles = props.tusUpload.useRemoveAllFiles();
  const isUploading = props.tusUpload.useIsUploading();
  const hasResumableFiles = props.tusUpload.useHasResumableFiles();

  useEffect(() => {
    const resume = async () => {
      if (
        await confirm({
          title: "Uploading file",
          description: `Uploading a file was interrupted. Would you like to resume uploading?`,
          confirmText: "Resume uploading",
        })
      ) {
        resumeUploads();
      } else {
        removeAllFiles();
      }
    };

    if (hasResumableFiles) {
      void resume();
    }
  }, [confirm, hasResumableFiles, removeAllFiles, resumeUploads]);

  const [fileName, setFileName] = useState<string | undefined>();
  const [tempFileName, setTempFileName] = useState<string | undefined>();
  const [shouldFetchValidation, setShouldFetchValidation] = useState(false);
  const { isFetching: isLoadingValidationResults, data: validationResults } =
    useValidationResults({
      fileName,
      tempFileName,
    });

  const validationModalDisclosure = useDisclosure();

  useEffect(() => {
    if (validationResults && shouldFetchValidation) {
      validationModalDisclosure.onOpen();
    }
  }, [shouldFetchValidation, validationModalDisclosure, validationResults]);

  const dropzone = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize: 200 * 1024 * 1024,
    accept: { "text/csv": [".csv"] },
    onDropAccepted: ([file]) => {
      const fileName = getUniqueFileName(
        file.name,
        props.uploadedFileNames ?? []
      );

      setFileName(fileName);
      const upload = uploadFile(file, {
        onSuccess() {
          setShouldFetchValidation(true);
        },
        onUploadUrlAvailable() {
          if (!upload.url) {
            throw new Error("Upload URL is not available");
          }

          setTempFileName(upload.url.slice(upload.url.lastIndexOf("/") + 1));
        },
      });
    },
    onDropRejected: () => {
      toast({
        title: "Invalid file",
        description: "Please upload a CSV file",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });

  const fileUploading = files.find((i) => i.status === "uploading");

  const uploadStatus = getUploadStatus({
    progress,
    isLoadingValidationResults,
    hasValidationResults: Boolean(validationResults),
    isUploading,
    shouldFetchValidation,
  });

  const resetState = () => {
    setShouldFetchValidation(false);
    setFileName(undefined);
    setTempFileName(undefined);
    validationModalDisclosure.onClose();
    queryClient.resetQueries(["tempFileValidation", tempFileName]);
  };

  useStartValidation(
    {
      fileName,
      tempFileName,
    },
    { enabled: shouldFetchValidation }
  );

  return (
    <Flex flexDir="column">
      <Upload
        testId="domains-upload-zone"
        subHeader="File up to 200MB"
        getInputProps={dropzone.getInputProps}
        getRootProps={dropzone.getRootProps}
      />

      {uploadStatus && (
        <StatusModal
          isOpen={true}
          progressValue={progress.progress}
          status={uploadStatus.status}
          statusSubtitle={uploadStatus.statusSubtitle}
          onExit={
            fileUploading ? () => removeFile(fileUploading.id) : undefined
          }
        />
      )}

      {validationResults && (
        <ValidationResultsModal
          isOpen={validationModalDisclosure.isOpen}
          onCancel={() => {
            resetState();
          }}
          onProceed={() => {
            if (!tempFileName || !fileName) {
              return;
            }

            resetState();

            props.onFileUpload(tempFileName, fileName, validationResults.data);
          }}
          validationResults={validationResults}
        />
      )}
    </Flex>
  );
}

function useValidationResults(params: {
  fileName?: string;
  tempFileName?: string;
}) {
  const result = useQuery({
    queryKey: ["tempFileValidation", params.tempFileName],
    enabled: false,
    queryFn: async () => {
      if (!params.fileName || !params.tempFileName) {
        throw new Error("File name is not available");
      }

      const res = await apiService<SerializableTalDomainValidationResults>(
        Endpoints.AccountsUpload.Get.GetTempFileValidation(params.tempFileName)
      );

      return { data: res.data, done: true };
    },
  });

  return params.tempFileName ? result : { data: undefined, isFetching: false };
}

function useStartValidation(
  params: {
    fileName?: string;
    tempFileName?: string;
  },
  options: { enabled?: boolean }
) {
  const queryClient = useQueryClient();

  return useQuery({
    ...options,
    queryKey: ["tempFileValidation", "startValidation", params],
    queryFn: async () => {
      if (!params.fileName || !params.tempFileName) {
        throw new Error("File name is not available");
      }

      if (!process.env.VITE_REACT_APP_API_URL) {
        throw new Error("API URL is not available");
      }

      const res = await apiFetchService(
        `${
          process.env.VITE_REACT_APP_API_URL
        }${Endpoints.AccountsUpload.All.Validate(params.tempFileName)}`,
        { credentials: "include" }
      );

      const decoder = new TextDecoder();
      const reader = res.body?.getReader();

      let validationResults;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const value = await reader?.read();

        if (value?.done || !value?.value) {
          queryClient.setQueryData(
            ["tempFileValidation", params.tempFileName],
            {
              data: validationResults,
              done: true,
            }
          );
          break;
        }

        const decoded = decoder.decode(value.value);

        if (decoded === "error") {
          throw Error("Internal Server Error");
        }

        try {
          if (decoded !== "ping") {
            validationResults = JSON.parse(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              decoded
            ) as SerializableTalDomainValidationResults;

            queryClient.setQueryData(
              ["tempFileValidation", params.tempFileName],
              {
                data: validationResults,
                done: false,
              }
            );
          }
        } catch (error) {
          console.log(error);
        }
      }

      return res.body;
    },
  });
}
