import { useDisclosure } from "@chakra-ui/react";
import { ReportType, SaasRoleName, SaasRoleNames } from "@intentsify/types";
import { useMutation } from "@tanstack/react-query";
import { cloneCampaign } from "api";
import {
  Actions,
  ActionsProps,
  RequestSpotlightReportModal,
  StatusModal,
} from "components";
import { RequestProgramReviewModal } from "components/RequestProgramReview/RequestProgramReviewModal";
import { useMemo } from "react";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router";
import { CreateCampaignScreenDefinition } from "screens";
import { useUser } from "store/store.hooks";
import { useTrackRequestReporting } from "tracking/useTrackRequestReporting";
import {
  UseCampaignActionItemsArg,
  useCampaignActionItems,
} from "./useCampaignActionItems";

export type ActionItemsProps = Omit<
  UseCampaignActionItemsArg,
  "onClone" | "openRequestProgramReview"
> &
  Pick<ActionsProps, "numberOfVisibleItems" | "buttonsVariant" | "iconsOnly">;

const CampaignActionsItems = ({
  isDraft,
  campaignId,
  companyId,
  isLoading,
  numberOfVisibleItems,
  hasLeads,
  hasSpotlight,
  hasBeenMappedToConvertr,
  campaignCreatedDate,
  campaignStatusId,
  showCampaignDetailsAction,
  hasRelatedProgrammaticCampaigns,
  buttonsVariant = "ghost",
  iconsOnly = true,
  hasPixel,
}: ActionItemsProps) => {
  const user = useUser();
  const navigate = useNavigate();

  const { mutate: cloneMutation, isLoading: cloneLoading } = useMutation(
    () => cloneCampaign(campaignId),
    {
      onSuccess: (newCampaign) => {
        navigate(
          CreateCampaignScreenDefinition.navigate({
            campaignId: newCampaign.campaignId,
            step: 1,
          })
        );
      },
    }
  );

  const trackRequestReporting = useTrackRequestReporting();
  const requestProgramReviewModalDisclosure = useDisclosure({
    onOpen: () => {
      trackRequestReporting({
        campaignId,
        requestingDeck: ReportType.ProgramReview,
      });
    },
  });
  const items = useCampaignActionItems({
    isDraft,
    campaignId,
    companyId,
    isLoading,
    hasLeads,
    hasSpotlight,
    hasBeenMappedToConvertr,
    onClone: cloneMutation,
    campaignCreatedDate,
    campaignStatusId,
    showCampaignDetailsAction,
    hasRelatedProgrammaticCampaigns,
    openRequestProgramReview: requestProgramReviewModalDisclosure.onOpen,
    hasPixel,
  });

  const numberOfVisibleActionItems = useMemo(() => {
    const isAdminOrPublisher = (
      [SaasRoleName.Admin, SaasRoleName.Publisher] as SaasRoleNames[]
    ).includes(user?.saasRole || SaasRoleName.Unspecified);

    if (isAdminOrPublisher) {
      return numberOfVisibleItems ?? 1;
    }

    return 1;
  }, [numberOfVisibleItems, user]);

  return (
    <>
      <Actions
        items={items}
        isLoading={isLoading}
        numberOfVisibleItems={numberOfVisibleActionItems}
        buttonIcon={<FiMoreVertical />}
        buttonsVariant={buttonsVariant}
        iconsOnly={iconsOnly}
      />
      <StatusModal isOpen={cloneLoading} status="Cloning campaign..." />
      <RequestSpotlightReportModal campaignId={campaignId} />
      <RequestProgramReviewModal
        campaignId={campaignId}
        isOpen={requestProgramReviewModalDisclosure.isOpen}
        onClose={requestProgramReviewModalDisclosure.onClose}
      />
    </>
  );
};

export { CampaignActionsItems };
