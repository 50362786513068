import { Company, ReportType, TalToolsTypes } from "@intentsify/types";

export const Endpoints = {
  Suggestions: {
    Topics: {
      Post: {
        List: "/suggestions/topics",
      },
    },
  },
  Signals: {
    Get: {
      All: `/signals`,
      Topics: `/signals/topics`,
      Keywords: `signals/keywords`,
      TopicsAggregated: `/signals/topic-aggregated`,
      PixelIdentifiers: `/signals/pixel-identifiers`,
    },
    Post: {
      Keywords: {
        Create: `signals/keywords`,
        Verify: `signals/keywords/verify`,
      },
      Topics: {
        Verify: `signals/topics/verify`,
      },
    },
  },
  TalTools: {
    Upload: "/tal-tools/upload",
    Files: (type: TalToolsTypes) => `/tal-tools/${String(type)}/files`,
    Delete: (type: TalToolsTypes, fileName: string) =>
      `/tal-tools/${String(type)}/${encodeURIComponent(fileName)}`,
    FirmoTechno: {
      Post: `/tal-tools/${String(TalToolsTypes.FIRMO_AND_TECHNO)}/append`,
      TechnoProductFilters: `/tal-tools/${String(
        TalToolsTypes.FIRMO_AND_TECHNO
      )}/products`,
    },
    CompanyNameToDomain: {
      Post: `/tal-tools/${String(
        TalToolsTypes.COMPANY_NAME_TO_DOMAIN
      )}/request`,
    },
    SmarTAL: {
      Post: `/tal-tools/${String(TalToolsTypes.SMARTAL)}/request`,
    },
    Download: (type: TalToolsTypes, fileName: string) =>
      `/download/tal-tools/${String(type)}/${encodeURIComponent(fileName)}`,
  },
  TargetPersonas: {
    Get: {
      All: "/target-persona/all",
      Paginated: "/target-persona",
    },
    Post: {
      Create: "/target-persona",
    },
    Put: {
      Batch: "/target-persona",
    },
    Delete: {
      Batch: "/target-persona",
    },
    Upload: "/target-persona/upload",
    BlocklistMetadata: {
      Get: "/target-persona/blocklist",
      Delete: "/target-persona/blocklist",
    },
    JobFunctions: {
      Get: {
        All: "/target-persona/job-functions",
      },
    },
    JobLevels: {
      Get: {
        All: "/target-persona/job-levels",
      },
    },
  },

  ContentLibrary: {
    All: {
      Upload: "/content-library/upload",
    },
    DraftAssets: {
      Get: {
        ListPaginated: "/content-library/draft-assets",
      },
      Post: {
        Review: (draftAssetId: number) =>
          `/content-library/draft-assets/${draftAssetId}/review`,
      },
      Delete: {
        Batch: "/content-library/draft-assets",
      },
    },
    ReviewedAssets: {
      Get: {
        ListPaginated: "/content-library/reviewed-assets",
      },
      Put: {
        Batch: "/content-library/reviewed-assets",
      },
      Delete: {
        Batch: "/content-library/reviewed-assets",
      },
    },
    Download: (fileName: string, id: string) =>
      `/download/content-library/${encodeURIComponent(
        fileName
      )}/${encodeURIComponent(id)}`,
  },
  Users: {
    Post: {
      Create: "/users",
      ResetPassword: (email: string) =>
        `/users/reset-password/${email.toLowerCase()}`,
      Settings: {
        Notifications: (id: number) => `/users/${id}/settings/notifications`,
      },
    },
    Get: {
      List: "/users",
      ById: (id: number | string) => `/users/${id}`,
      Settings: {
        Notifications: (id: number) => `/users/${id}/settings/notifications`,
      },
    },
    Put: {
      Edit: (id: number | string) => `/users/${id}`,
      Agreement: (id: number | string) => `/users/${id}/agreement`,
      SetPassword: (id: number | string) => `/users/${id}/set-password/`,
    },
    Delete: (id: string | number) => `/users/${id}`,
  },
  Campaigns: {
    Get: {
      Autodiscovery: {
        Status: (id: number | string) => `/campaigns/${id}/autodiscovery`,
        DownloadMetadata: (id: number | string) =>
          `/campaigns/${id}/autodiscovery/download-metadata`,
      },
      Paginated: "/campaigns",
      UserCampaignsSummary: "/campaigns/summary",
      PaginatedFirmographicCompanies: `/firmographics/companies`,
      PaginatedFirmographicDomains: `/firmographics/domains`,
      ById: (id: number | string) => `/campaigns/${id}`,
      Meta: (id: number | string) => `/campaigns/${id}/meta`,
      DownloadMultipleCampaignData: () => `/campaigns/campaigns-files`,
      DownloadMultipleCampaignMetadata: () => `/campaigns/campaigns-metas`,
      DownloadMultipleCampaignAccountMappings: () =>
        `/campaigns/campaigns-accounts-mappings`,
      DownloadMultipleLeadActivationSummaries: () =>
        `/campaigns/lead-activation-summaries`,
      Brief: (id: number | string) => `/campaigns/${id}/brief`,
      Details: {
        Paginated: (id: number | string) => `/campaigns/${id}/details`,
      },
      BuyingGroup: (id: number) => `/campaigns/${id}/buying-group`,
      BuyingGroupInsights: (id: number) =>
        `/campaigns/${id}/buying-group/insights`,
      DownloadBuyingGroupIntentFeed: (id: number) =>
        `/campaigns/${id}/buying-group/download-buying-group-intent-feed`,
      BuyingGroupPersonaDetails: (id: number, accountId: number) =>
        `/campaigns/${id}/buying-group/${accountId}/persona-details`,
      BuyingGroupAvailableSignals: (id: number, accountId: number) =>
        `/campaigns/${id}/buying-group/${accountId}/available-signals`,
      BuyingGroupTopics: (id: number, accountId: number) =>
        `/campaigns/${id}/buying-group/${accountId}/topics`,
      BuyingGroupListAllPersonas: (id: number, accountId: number) =>
        `/campaigns/${id}/buying-group/${accountId}/list-all-personas`,
      Domains: {
        TopPerformingDomains: (id: number) =>
          `/campaigns/${id}/domains/top-performing`,
        TopTrendingDomains: (id: number) =>
          `/campaigns/${id}/domains/top-trending`,
        TopPerformingDomainsWithTimeseries: (id: number) =>
          `/campaigns/${id}/domains/top-performing-with-timeseries`,
        Details: (id: number) => `/campaigns/${id}/domains/details`,
        DownloadTempFile: (campaignID: number, fileName: string) =>
          `/campaigns/${campaignID}/domains/download-temp-file/${fileName}`,
        DownloadDomainsFile: (campaignID: number, fileName: string) =>
          `/campaigns/${campaignID}/domains/download-domains-file/${fileName}`,
        DownloadBlocklistedDomainsFile: (
          campaignID: number,
          fileName: string
        ) =>
          `/campaigns/${campaignID}/domains/download-blocklisted-domains-file/${fileName}`,
        DownloadTAL: (campaignID: number) =>
          `/campaigns/${campaignID}/domains/download-tal-file`,
        DownloadTALSummary: (campaignID: number) =>
          `/campaigns/${campaignID}/domains/download-tal-summary-file`,
        DownloadFirmographicsFile: (campaignID: number) =>
          `/campaigns/${campaignID}/domains/download-firmographics-file`,
      },
      Signals: {
        MostActiveSignals: (id: number) =>
          `/campaigns/${id}/signals/most-active`,
        SignalActivityWithTimeseries: (id: number) =>
          `/campaigns/${id}/signals/activity-with-timeseries`,
        AvailableSignalTypes: (id: number) =>
          `/campaigns/${id}/signals/available-signal-types`,
        TopSignals: (id: number) => `/campaigns/${id}/signals/top`,
      },
      Leads: {
        Paginated: (id: number) => `/campaigns/${id}/leads`,
        PaginatedAccounts: (id: number) => `/campaigns/${id}/leads/accounts`,
        DownloadSelection: (id: number) => `/campaigns/${id}/leads/download`,
        Performance: (id: number) => `/campaigns/${id}/leads/performance`,
        PerformanceFilters: (id: number) =>
          `/campaigns/${id}/leads/performance-filters`,
      },
      DataSources: `/campaigns/data-sources`,
      Countries: `/campaigns/countries`,
      Companies: `/campaigns/customers`,
      CorporateEntities: `/campaigns/corporate-entities`,
      RepresentativeNames: `/campaigns/representative-names`,
      CampaignManagers: `/campaigns/campaign-managers`,
      FirmographicsFilters: () => `/firmographics/filters`,
      CompanyBusinessUnits: (id: number | string) =>
        `/campaigns/${id}/business-units`,
      WeeklySnapshot: {
        AccountsInBuyerResearchStageStatistics: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/accounts-in-buyer-research-stage-statistics`,
        CoverageByEmployeeSize: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/coverage-by-employee-size`,
        CoverageByRevenueSize: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/coverage-by-revenue-size`,
        CoverageByTopIndustries: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/coverage-by-top-industries`,
        BuyerResearchStageMovement: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/get-buyer-research-stage-movement`,
        MostEngagedAccounts: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/most-engaged-accounts`,
        MostResearchedTopics: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/most-researched-topics`,
        ContentAlignmentAnalysis: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/content-alignment-analysis`,
        PingContent: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/ping-content`,
        LeadByAssetPerformance: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/lead-by-asset-performance`,
        TrendingTopics: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/trending-topics`,
        TopCreativePerformance: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/top-creative-performance`,
        TopicsByLocation: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/topics-by-location`,
        ImpressionsByLocation: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/impressions-by-location`,
        LeadsPerformance: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/leads-performance`,
        TopAccountsBySpend: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/top-accounts-by-spend`,
        AccountsReachedOverTime: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/accounts-reached-over-time`,
        ImpressionsAndClicksOverTime: (id: number) =>
          `/campaigns/${id}/weekly-snapshot/impressions-and-clicks-over-time`,
      },
      Firmographics: {
        Download: `/firmographics-download`,
      },
    },
    Post: {
      Autodiscovery: {
        Process: (id: number | string) => `/campaigns/${id}/autodiscovery`,
        MutateKeywordsAndTopics: (id: number | string) =>
          `/campaigns/${id}/autodiscovery/keywords-and-topics`,
        UploadFiles: (id: string) => `/campaigns/${id}/autodiscovery/files`,
      },
      Create: "/campaigns",
      Clone: (id: number) => `/campaigns/${id}/clone`,
      Domains: {
        SaveDomains: (id: number) => `/campaigns/${id}/domains/save`,
      },
      Signals: {
        KeywordsUpload: (id: number) =>
          `/campaigns/${id}/signals/keywords/upload`,
        TopicsUpload: (id: number) => `/campaigns/${id}/signals/topics/upload`,
        KeywordsSave: (id: number) => `/campaigns/${id}/signals/keywords/save`,
      },
      Firmographics: {
        Paginated: `/firmographics:filter`,
        Count: `/firmographics-count`,
      },
    },
    Put: {
      Settings: (id: number) => `/campaigns/${id}/settings`,
      AdvancedSettings: (id: number) => `/campaigns/${id}/advanced-settings`,
      BuyingGroup: (id: number) => `/campaigns/${id}/buying-group`,
      KeywordsAndTopics: (id: number) => `/campaigns/${id}/keywords-and-topics`,
      SaveAccounts: (id: number) => `/campaigns/${id}/accounts/save`,
      Autodiscovery: {
        DisableDynamic1: (id: number | string) =>
          `/campaigns/${id}/autodiscovery/disable-dynamic-one`,
        MutateKeywords: (id: number | string) =>
          `/campaigns/${id}/autodiscovery/keywords`,
        ExcludeDynamic1Article: (id: number | string) =>
          `/campaigns/${id}/autodiscovery/exclude-dynamic-one-article`,
      },
      UpdateCampaignFavoriteStatus: (id: number) =>
        `/campaigns/${id}/favorite-status`,
    },
    Delete: {
      Autodiscovery: {
        Url: (campaignId: string, urlId: string) =>
          `/campaigns/${campaignId}/autodiscovery/files/${urlId}`,
      },
    },
  },
  CampaignDetails: {
    Get: {
      List: (campaignId: number | string) => `/campaign-details/${campaignId}`,
      Filters: (campaignId: number | string) =>
        `/campaign-details/${campaignId}/filters`,
      AvailableSignals: (campaignId: number | string, domainId: number) =>
        `/campaign-details/${campaignId}/domains/${domainId}/available-signals`,
      AggregatedSignal: (
        campaignId: number | string,
        domainId: number,
        signalId: number
      ) =>
        `/campaign-details/${campaignId}/domains/${domainId}/signals/${signalId}`,
      TopLocations: (campaignId: number | string, domainId: number) =>
        `/campaign-details/${campaignId}/domains/${domainId}/top-locations`,
      DownloadSelection: (id: number | string) =>
        `/campaign-details/${id}/download-selection`,
      DownloadBuyingGroup: (id: number | string) =>
        `/campaign-details/${id}/download-buying-group`,
      Summary: (id: number) => `/campaign-details/${id}/summary`,
      Spotlight: (campaignId: number | string, domainId: number) =>
        `/campaign-details/${campaignId}/domains/${domainId}/spotlight`,
    },
    POST: {
      DownloadLeadActivationSummaries: (campaignId: number) =>
        `/campaign-details/${campaignId}/domains/lead-activation-summary/download`,
    },
  },
  Customers: {
    Get: {
      All: "/customers/all",
    },
  },
  Representatives: {
    Get: {
      All: "/representatives",
    },
  },
  Roles: {
    Get: {
      All: "/roles",
    },
  },
  DigitalForecasting: {
    Post: {
      Forecast: () => `/digital-forecasting:forecast`,
    },
  },
  Companies: {
    Post: {
      CreateCompany: "/companies",
      CreateBusinessUnit: (companyId: number) =>
        `/companies/${companyId}/business-units`,
      EstablishWorkatoMapping: (companyId: number) =>
        `/companies/${companyId}/integrations/workato-establish-mapping`,
      EstablishWorkatoIntegration: (companyId: number) =>
        `/companies/${companyId}/integrations/workato-establish-integration`,
      Integrations: {
        AssignCampaigns: () =>
          `/companies/campaigns-assigned-to-integrations/workato`,
      },
      CreateAudienceSegment: (companyId: number) =>
        `/companies/${companyId}/audience-segments`,
    },
    Get: {
      Paginated: `/companies`,
      All: "/companies/all",
      DataPackage: (id: string) => `/companies/${id}/data-package`,
      TrialEndDate: (id: string) => `/companies/${id}/trial-end-date`,
      ById: (id: string) => `/companies/${id}`,
      ListBusinessUnits: (companyId: number) =>
        `/companies/${companyId}/business-units`,
      ListActiveCampaigns: (companyId: number) =>
        `/companies/${companyId}/active-campaigns`,
      ListPastCampaigns: (companyId: number) =>
        `/companies/${companyId}/past-campaigns`,
      getWorkatoToken: (companyId: number, workatoCustomerId: number) =>
        `/companies/${companyId}/integrations/workato-embedded-token/${workatoCustomerId}`,
      listWorkatoConnections: (companyId: number, workatoCustomerId: number) =>
        `/companies/${companyId}/integrations/workato-connections/${workatoCustomerId}`,
      Integrations: {
        Paginated: (id: Company["companyId"]) =>
          `/companies/campaigns-assigned-to-integrations/${id}/workato`,
      },
      ListAudienceSegments: (companyId: number) =>
        `/companies/${companyId}/audience-segments/`,
    },
    Put: {
      EditCompany: (id: string | number) => `/companies/${id}`,
      EditBusinessUnit: (companyId: number, unitId: number) =>
        `/companies/${companyId}/business-units/${unitId}`,
      AudienceSegment: (companyId: number, id: number) =>
        `/companies/${companyId}/audience-segments/${id}`,
    },
    Delete: {
      ById: (id: string | number) => `/companies/${id}`,
      BusinessUnit: (companyId: number, unitId: number) =>
        `/companies/${companyId}/business-units/${unitId}`,
      Integrations: {
        UnAssignCampaigns: () =>
          `/companies/campaigns-assigned-to-integrations/workato`,
      },
      AudienceSegment: (companyId: number, id: number) =>
        `/companies/${companyId}/audience-segments/${id}`,
    },
  },
  BusinessEntities: {
    Post: {
      Create: "/business-entities",
    },
    Get: {
      Paginated: "/business-entities",
      ById: (id: number) => `/business-entities/${id}`,
      All: `/business-entities/all`,
    },
    Put: {
      Update: (id: number) => `/business-entities/${id}`,
    },
    Delete: {
      ById: (id: number) => `/business-entities/${id}`,
    },
  },
  Auth: {
    Post: {
      LogOut: "/auth/log-out",
      LogIn: "/auth/log-in",
    },
    Get: {
      Refresh: "/auth/refresh",
    },
  },
  ProgrammaticCampaigns: {
    Get: {
      Paginated: "programmatic-campaigns",
      ById: (id: number) => `programmatic-campaigns/${id}`,
    },
    ImpressionsAndClicks: {
      Get: {
        Statistics: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getStatistics`,
        EngagementBreakdown: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getEngagementBreakdown`,
        ImpressionsByLocation: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getImpressionsByLocation`,
        TopCreativePerformance: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:topCreativePerformance`,
        TopicsByLocation: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getTopicsByLocation`,
        SiteEngagementLift: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getSiteEngagementLift`,
        IntegratedCampaignReport: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getIntegratedCampaignReport`,
        PerformanceByDevice: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance/performance-by-device`,
        MobileDeliveryBreakdown: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance/mobile-delivery-breakdown`,
      },
    },
    Accounts: {
      Get: {
        AccountLifts: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getAccountLifts`,
        TopAccounts: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getTopAccounts`,
        AccountsInBuyerResearchStage: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getAccountsInBuyerResearchStage`,
        AccountEngagement: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getActiveAccountEngagements`,
        OverallViewability: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getOverallViewability`,
        EngagementAcrossPersonas: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance/engagement-across-personas`,
        AccountAndPersonasInsights: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance/account-and-persona-insights`,
      },
    },
    CreativeEngagement: {
      Get: {
        CreativeWithHighestEngagement: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getCreativesEngagements`,
        FatigueMap: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getFatigueMap`,
        EngagementTestingResults: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getCreativeEngagementTesting`,
        OverallVideoMap: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getOverallVideoViews`,
        VideoCompletionRate: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getVideoCompletionRate`,
        BuyerResearchStageEngagement: (id: number) =>
          `programmatic-campaigns/${id}/digital-performance:getBuyerResearchStageEngagement`,
      },
    },
  },
  AutomatedProgrammaticSegment: {
    Get: {
      AutomatedProgrammaticSegments: "/automated-programmatic-segments",
      AutomatedProgrammaticSegment: (id: number) =>
        `/automated-programmatic-segments/${id}`,
      DownloadAutomatedProgrammaticSegment: (id: number) =>
        `/automated-programmatic-segments/${id}/download`,
      ActiveAccounts: `/automated-programmatic-segments/active-accounts`,
    },
    Put: {
      AutomatedProgrammaticSegment: (id: number) =>
        `/automated-programmatic-segments/${id}`,
      StopOrStartAutomatedProgrammaticSegment: (
        id: number,
        action: "start" | "stop"
      ) => `/automated-programmatic-segments/${id}/${action}`,
    },
    Post: {
      AutomatedProgrammaticSegment: "/automated-programmatic-segments",
    },
  },
  ProgrammaticDomains: {
    Post: {
      Upload: "/programmatic-domains/upload",
    },
    Get: {
      Paginated: "/programmatic-domains",
      FileById: (id: number) => `/programmatic-domains/${id}`,
      Download: (id: number) => `/programmatic-domains/${id}/download`,
    },
  },
  Publishers: {
    Get: {
      List: `/publishers`,
      ListAllCampaigns: "/publishers/list-all-campaigns",
      ListCampaigns: (id: string) => `publishers/${id}/campaigns`,
    },
    Post: {
      AssignCampaign: (publisherId: string) =>
        `/publishers/${publisherId}/campaigns`,
    },
    Delete: {
      UnassignCampaign: (publisherId: string, campaignId: number) =>
        `/publishers/${publisherId}/campaigns/${campaignId}`,
    },
  },
  Impersonation: {
    Post: {
      Enable: "/impersonation/enable",
      Disable: "/impersonation/disable",
    },
  },
  PageTracking: {
    Get: {
      All: "/page-tracking",
    },
  },
  Domains: {
    Post: {
      IpResolution: "/domains/ip-resolution",
      VerifyDomains: "/domains/verify",
    },
  },
  Notifications: {
    Get: {
      Paginated: "/notifications",
    },
    Post: {
      MarkAsRead: "/notifications/mark-as-read",
      MarkAllAsRead: "/notifications/mark-all-as-read",
    },
  },
  Permissions: {
    Get: {
      PermissionModules: "/permissions/permission-modules",
      DataPackages: "/permissions/data-packages",
    },
  },
  UserAgencyCompany: {
    Get: "/user-agency-company",
    Post: {
      Select: (companyId: number) => `/user-agency-company/${companyId}`,
    },
  },
  Download: {
    Get: {
      File: ({
        bucket,
        s3File,
        filename,
      }: {
        bucket: string;
        s3File: string;
        filename: string;
      }) => `/download/${bucket}/${s3File}/${filename}`,
    },
  },
  Reports: {
    WeeklySnapshot: {
      Post: {
        RequestReport: (id: number) => `/reports/weekly-snapshot/${id}`,
      },
    },
    Spotlight: {
      Get: {
        AvailableSpotlightDeckWeeks: `/reports/spotlight/available-spotlight-deck-weeks`,
        AvailableSpotlightDeckAccounts: `/reports/spotlight/available-spotlight-deck-accounts`,
      },
      Post: {
        RequestSpotlightDeck: `/reports/spotlight/request-spotlight-deck`,
        RequestDeck: `/reports/spotlight/request-deck`,
      },
    },
    ProgramReview: {
      Post: {
        RequestProgramReview: `/reports/program-review/request-program-review-deck`,
      },
    },
    CumulativeReport: {
      Post: {
        Request: (id: number) => `/reports/cumulative/${id}`,
      },
    },
    DownloadReport: {
      Get: ({
        campaignId,
        correlationId,
        reportType,
      }: {
        campaignId: string;
        correlationId: string;
        reportType: ReportType;
      }) => `/download/report/${campaignId}/${correlationId}/${reportType}`,
    },
  },
  Version: {
    Get: {
      Version: "/version",
    },
  },
  BulkOperations: {
    Post: {
      BulkEditCampaigns: "/bulk-operations/edit-campaigns",
    },
  },
  AccountsUpload: {
    All: {
      Upload: "/accounts-upload",
      Validate: (tempFileName: string) =>
        `/accounts-upload/validate/${tempFileName}`,
    },
    Get: {
      GetTempFileValidation: (tempFileName: string) =>
        `/accounts-upload/temp-file-validation/${encodeURIComponent(
          tempFileName
        )}`,
    },
  },
  PersonalInfo: {
    Create: "/personal-info",
    GetInfo: (token: string) => `/personal-info/${token}`,
    GetInfoChange: (token: string) => `/personal-info/edit/${token}`,
    Edit: (token: string) => `/personal-info/${token}`,
    ConfirmDeleteRequest: (token: string) =>
      `/personal-info/confirm/${token}/delete`,
    ConfirmOptOutRequest: (token: string) =>
      `/personal-info/confirm/${token}/opt-out`,
    ConfirmOptOutProcessingRequest: (token: string) =>
      `/personal-info/confirm/${token}/opt-out-processing`,
  },
};
