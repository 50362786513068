import { useToast } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { CampaignStatus, ReportType, ReportTypeLabel } from "@intentsify/types";
import { getExportFileName, isDefined } from "@intentsify/utils";
import { Endpoints } from "api";
import { requestSpotlightReportModalAtom } from "components/RequestSpotlightReportModal";
import {
  FiAperture,
  FiCopy,
  FiDownload,
  FiEdit,
  FiFileText,
} from "react-icons/fi";
import { MdOutgoingMail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  CampaignDetailsScreenDefinition,
  CreateCampaignScreenDefinition,
  DisplayPerformanceScreenDefinition,
  LeadPacingScreenDefinition,
  WeeklySnapshotScreenDefinition,
  WeeklySnapshotView,
  useEditCampaignNavigation,
} from "screens";
import { useTrackCampaignFileDownloaded } from "tracking/useTrackCampaignFileDownloaded";
import { useTrackRequestReporting } from "tracking/useTrackRequestReporting";
import { ActionItems } from "types";
import { useDownloadFile } from "utils";
import { useRequestCumulativeCampaignReport } from "../../../../mutations/campaigns/useRequestCumulativeCampaignReport";

export interface UseCampaignActionItemsArg {
  campaignId: number;
  campaignCreatedDate: string | undefined;
  companyId: number | undefined;
  hasBeenMappedToConvertr: boolean | undefined;
  hasLeads: boolean | undefined;
  hasSpotlight: boolean | undefined;
  campaignStatusId: number | undefined;
  onClone: () => void;
  isLoading?: boolean;
  showCampaignDetailsAction: boolean;
  hasRelatedProgrammaticCampaigns: boolean;
  isDraft?: boolean;
  openRequestProgramReview: () => void;
  hasPixel: boolean;
}

const getIsDisabledSpotlightTootlip = (
  hasBeenMappedToConvertr: boolean,
  isCampaignActive: boolean,
  hasSpotlight: boolean
) => {
  if (!hasBeenMappedToConvertr) {
    return "In order to select, please remember to map your Intentsify campaign to a CID within our lead validation platform, Convertr.";
  }

  if (!isCampaignActive) {
    return "The report could not be generated because the campaign is not active.";
  }

  if (!hasSpotlight) {
    return "The report is not available at this time.";
  }
};

export const useCampaignActionItems = ({
  isDraft,
  campaignId,
  onClone,
  isLoading,
  companyId,
  hasBeenMappedToConvertr,
  hasLeads,
  hasSpotlight,
  campaignStatusId,
  showCampaignDetailsAction,
  hasRelatedProgrammaticCampaigns,
  openRequestProgramReview,
  hasPixel,
}: UseCampaignActionItemsArg) => {
  const { download } = useDownloadFile();

  const [, setRequestSpotlightReportModal] = useRecoilState(
    requestSpotlightReportModalAtom(campaignId)
  );

  const isCampaignActive = campaignStatusId === CampaignStatus.Active;

  const hasAccessToSpotlight = useHasAccessTo(
    "campaign.intentAnalysisWithSpotlight"
  );
  const hasAccessToCampaignDetails = useHasAccessTo("campaign.details");
  const hasAccessToCampaignEdit = useHasAccessTo("campaign.edit");
  const hasAccessToCampaignClone = useHasAccessTo("campaign.clone");
  const hasAccessToDownload = useHasAccessTo("campaign.download");
  const hasAccessToDownloadAccountsMapping = useHasAccessTo(
    "campaign.downloadAccountMapping"
  );
  const hasAccessToProgramReview = useHasAccessTo(
    "campaign.requestProgramReview"
  );
  const hasAccessToBuyingGroupIntentFeed = useHasAccessTo(
    "campaign.buyingGroupIntentFeed"
  );

  const navigate = useNavigate();
  const editCampaignNavigation = useEditCampaignNavigation();
  const trackCampaignFileDownloaded = useTrackCampaignFileDownloaded();
  const trackRequestReporting = useTrackRequestReporting();

  const handleDownload = async (campaignIds: number[]) => {
    const fileName =
      campaignIds.length === 1
        ? getExportFileName({
            prefix: `intent_model`,
            extension: false,
            data: {
              id: campaignIds[0],
            },
          })
        : "intent_models";

    const params = {
      url: Endpoints.Campaigns.Get.DownloadMultipleCampaignData(),
      fileName,
      fileExtension: "zip",
      params: {
        campaignId: campaignIds.join(","),
      },
    };

    await download(params);

    trackCampaignFileDownloaded({ type: "campaign", campaignIds });
  };

  const handleMetadataDownload = async (campaignIds: number[]) => {
    const fileName =
      campaignIds.length === 1
        ? getExportFileName({
            prefix: `intent_model_metadata`,
            extension: false,

            data: {
              id: campaignIds[0],
            },
          })
        : "intent_models_metadata";

    const params = {
      fileName,
      fileExtension: "zip",
      url: Endpoints.Campaigns.Get.DownloadMultipleCampaignMetadata(),
      params: {
        campaignId: campaignIds.join(","),
      },
    };

    await download(params);

    trackCampaignFileDownloaded({ type: "campaign metadata", campaignIds });
  };

  const handleLeadActivationSummariesDownload = async (
    campaignIds: number[]
  ) => {
    const fileName =
      campaignIds.length === 1
        ? getExportFileName({
            prefix: `lead_activation_summaries`,
            extension: false,
            data: {
              id: campaignIds[0],
            },
          })
        : "lead_activation_summaries";

    const params = {
      fileName,
      fileExtension: "zip",
      url: Endpoints.Campaigns.Get.DownloadMultipleLeadActivationSummaries(),
      params: {
        campaignId: campaignIds.join(","),
      },
    };

    await download(params);
    trackCampaignFileDownloaded({
      type: "campaign lead activation summaries",
      campaignIds,
    });
  };

  const handleAccountMappingDownload = async (campaignIds: number[]) => {
    const fileName =
      campaignIds.length === 1
        ? getExportFileName({
            prefix: `intent_model_accounts_mapping`,
            extension: false,
            data: {
              id: campaignIds[0],
            },
          })
        : "intent_models_accounts_mapping";

    const params = {
      url: Endpoints.Campaigns.Get.DownloadMultipleCampaignAccountMappings(),
      fileName,
      fileExtension: "zip",
      params: {
        campaignId: campaignIds.join(","),
      },
    };

    await download(params);

    trackCampaignFileDownloaded({
      type: "campaign account mapping",
      campaignIds,
    });
  };

  const handleBuyingGroupIntentFeedDownload = async (campaignId: number) => {
    const fileName = getExportFileName({
      prefix: `intent_model_buying_group_intent_feed`,
      extension: false,
      data: {
        id: campaignId,
      },
    });

    const params = {
      url: Endpoints.Campaigns.Get.DownloadBuyingGroupIntentFeed(campaignId),
      fileName,
      fileExtension: "zip",
      params: {
        campaignId: campaignId,
      },
    };

    await download(params);

    trackCampaignFileDownloaded({
      type: "campaign buying group intent feed",
      campaignIds: [campaignId],
    });
  };

  const toast = useToast();

  const { mutate: requestCumulativeReportMutation } =
    useRequestCumulativeCampaignReport({
      onSuccess: () => {
        toast({
          title: "Report requested.",
          status: "success",
        });
      },
    });

  const items: ActionItems[] = [
    {
      title: "INTENT MODEL BUILD MANAGEMENT",
      items: [
        ...((showCampaignDetailsAction && hasAccessToCampaignDetails
          ? [
              {
                label: "Campaign Details",
                accessPath: "campaign.details",
                icon: <FiFileText />,
                isDisabled: isLoading || isDraft,
                onClick: () =>
                  navigate(
                    CampaignDetailsScreenDefinition.navigate({
                      campaignId,
                      view: "account-details",
                    })
                  ),
              },
            ]
          : []) as any),
        ...(hasAccessToCampaignEdit
          ? [
              isDraft
                ? {
                    label: "Continue creating",
                    accessPath: "campaign.edit",
                    icon: <FiEdit />,
                    onClick: () =>
                      navigate(
                        CreateCampaignScreenDefinition.navigate({
                          campaignId,
                          step: 1,
                        })
                      ),
                  }
                : undefined,
              {
                label: "Edit",
                accessPath: "campaign.edit",
                icon: <FiEdit />,
                isDisabled: isLoading || isDraft,
                onClick: () =>
                  editCampaignNavigation({
                    campaignId,
                    step: 1,
                  }),
              },
            ]
          : []),
        ...(hasAccessToCampaignClone
          ? [
              {
                label: "Clone",
                accessPath: "campaign.clone",
                icon: <FiCopy />,
                isDisabled: isLoading || isDraft,
                onClick: onClone,
              },
            ]
          : []),
      ].filter(isDefined),
    },
    {
      title: "DOWNLOAD",
      items: hasAccessToDownload
        ? [
            {
              label: "Intent Model",
              accessPath: "campaign.download",
              icon: <FiDownload />,
              isDisabled: isLoading || isDraft,
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick: () => handleDownload([campaignId]),
            },
            {
              label: "Intent Model's Metadata",
              accessPath: "campaign.downloadMetadata",
              icon: <FiDownload />,
              isDisabled: isLoading || isDraft,
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick: () => handleMetadataDownload([campaignId]),
            },
            {
              label: "Intent Model Account Mapping",
              accessPath: "campaign.downloadAccountMapping",
              icon: <FiDownload />,
              isDisabled: isLoading || isDraft,
              isHidden: !hasAccessToDownloadAccountsMapping,
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick: () => handleAccountMappingDownload([campaignId]),
            },
            {
              label: "Lead Activation Summaries",
              accessPath: "campaign.leadActivationSummaries",
              icon: <FiDownload />,
              isDisabled: isLoading || isDraft,
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick: () =>
                handleLeadActivationSummariesDownload([campaignId]),
            },
            {
              label: "Buying Group Intent Feed",
              accessPath: "campaign.buyingGroupIntentFeed",
              icon: <FiDownload />,
              isDisabled: isLoading || isDraft,
              isHidden: !hasAccessToBuyingGroupIntentFeed,
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick: () => handleBuyingGroupIntentFeedDownload(campaignId),
            },
          ]
        : [
            {
              label: "Intent Model's Metadata",
              accessPath: "campaign.downloadMetadata",
              icon: <FiDownload />,
              isDisabled: isLoading,
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick: () => handleMetadataDownload([campaignId]),
            },
          ],
    },
    {
      title: "INTENT MODEL PERFORMANCE",
      items: [
        {
          label: LeadPacingScreenDefinition.name,
          accessPath: "campaign.leadGeneration",
          icon: <FiAperture />,
          isDisabled: !hasLeads || isDraft,
          onClick: () => {
            if (!companyId) {
              return;
            }
            navigate(
              LeadPacingScreenDefinition.navigate({ companyId, campaignId }),
              {
                replace: true,
                state: {},
              }
            );
          },
        },
        {
          label: DisplayPerformanceScreenDefinition.name,
          accessPath: "campaign.displayPerformance",
          isDisabled: !hasRelatedProgrammaticCampaigns || isDraft,
          icon: <FiAperture />,
          onClick: () =>
            navigate(
              DisplayPerformanceScreenDefinition.navigate({
                programmaticCampaignId: undefined,
                view: undefined,
              })
            ),
        },
        {
          label: WeeklySnapshotScreenDefinition.name,
          accessPath: "campaign.weeklySnapshot",
          isDisabled: isDraft,
          icon: <FiAperture />,
          onClick: () =>
            navigate(
              WeeklySnapshotScreenDefinition.navigate({
                campaignId,
                view: WeeklySnapshotView.OverallPerformance,
              })
            ),
        },
      ],
    },
    ...(hasAccessToSpotlight
      ? [
          {
            title: "REQUEST REPORTING",
            items: [
              {
                label: `${ReportTypeLabel[ReportType.Spotlight]}`,
                icon: <MdOutgoingMail />,
                isDisabled: !hasSpotlight || isDraft,
                isDisabledTooltip: getIsDisabledSpotlightTootlip(
                  hasBeenMappedToConvertr ?? false,
                  isCampaignActive ?? false,
                  hasSpotlight ?? false
                ),
                onClick: () => {
                  setRequestSpotlightReportModal({ campaignId });
                },
              },
              {
                label: `${ReportTypeLabel[ReportType.ProgramReview]}`,
                icon: <MdOutgoingMail />,
                isDisabled: isDraft,
                isHidden: !hasAccessToProgramReview,
                onClick: () => {
                  openRequestProgramReview();
                  trackRequestReporting({
                    campaignId,
                    requestingDeck: ReportType.ProgramReview,
                  });
                },
              },
              {
                label: `Cumulative Ad Solutions`,
                icon: <MdOutgoingMail />,
                isDisabled: !hasRelatedProgrammaticCampaigns || isDraft,
                onClick: () => {
                  requestCumulativeReportMutation({
                    id: campaignId,
                    params: {
                      type: ReportType.CumulativeAdSolutions,
                    },
                  });
                },
              },
              {
                label: `Cumulative Intent Feed - BRS WoW`,
                icon: <MdOutgoingMail />,
                isDisabled: isDraft,
                onClick: () => {
                  requestCumulativeReportMutation({
                    id: campaignId,
                    params: {
                      type: ReportType.CumulativeIntentFeedBRS,
                    },
                  });
                },
              },
              // {
              //   label: `Cumulative Intent Feed - Topics WoW`,
              //   icon: <MdOutgoingMail />,
              //   isDisabled: isDraft,
              //   onClick: () => {
              //     requestCumulativeReportMutation({
              //       id: campaignId,
              //       params: {
              //         type: ReportType.CumulativeIntentFeedTopics,
              //       },
              //     });
              //   },
              // },
              {
                label: `Cumulative Site Pixel Report`,
                icon: <MdOutgoingMail />,
                isDisabled: isDraft || !hasPixel,
                onClick: () => {
                  requestCumulativeReportMutation({
                    id: campaignId,
                    params: {
                      type: ReportType.CumulativeSitePixelData,
                    },
                  });
                },
              },
            ],
          },
        ]
      : []),
  ];

  return items;
};
